.sidenav {
  height: 100vh;
  background-color: #f5f5f5;
  position: fixed;
  z-index: 100;
  left: -100%;
  margin-top: 3rem;
  transition: transform 0.3s ease-out;
}

.sidenav-open {
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}
